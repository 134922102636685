import router from 'next/router'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'

import {Button} from 'components'
import {I18N, PATH} from 'utils/constants'


export interface Props {
  landing?: boolean
}

const Container = styled.div<{landing?: boolean}>`
  background-color: ${({theme, landing}) => landing ? theme.colorGrayDark : theme.colorWhite};
  border-top: ${({theme}) => theme.line} solid ${({theme, landing}) => landing ? theme.colorTransparent : theme.colorGrayAccent};
`
const Legal = styled.div<{narrow?: boolean, landing?: boolean}>`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: ${({theme}) => theme.content};
  padding: 0 ${({theme}) => theme.contentPadding};
  color: ${({theme, landing}) => landing ? theme.colorWhite : theme.colorGrayDark};
  & > * {
    margin-left: 0 !important;
    margin-right: ${({theme}) => theme.size};
  }
  & > span {
    padding: ${({theme}) => theme.size} 0;
  }
`

const PageLegalFooter = ({landing}: Props) => {

  const {t} = useTranslation()

  return(
    <Container landing={landing}>
      <Legal landing={landing}>
        <span>RightSize © {new Date().getFullYear()}. {t(`${I18N.COMMON}:footer.rightsReserved`)}</span>
        <Button link onClick={() => router.push(PATH.LEGAL_PRIVACY)}>{t(`${I18N.COMMON}:footer.privacy`)}</Button>
        <Button link onClick={() => router.push(PATH.LEGAL_TERMS)}>{t(`${I18N.COMMON}:footer.terms`)}</Button>
      </Legal>
    </Container>
  )
}

export default PageLegalFooter
